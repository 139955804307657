import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
import defaultSettings from '../settings';

const { defaultLanguage, fallbackLanguage } = defaultSettings;

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: fallbackLanguage,
  messages: loadLocaleMessages(),
  legacy: false,
})

export default i18n;