<template>
  <ul class="app-menu" :class="`app-menu--${view}`">
    <slot name="prepend-menu"> </slot>
    <router-link
      v-for="item in items"
      :key="item.id"
      v-slot="{ href, navigate, isActive }"
      :to="item.to"
      custom
    >
      <li :class="['app-menu__item', { active: isActive, 'with-icon': !!item.icon }]">
        <a class="app-menu__link" :href="href" @click="navigate">
          <v-icon v-if="!!item.icon" class="app-menu__item-icon" :color="item.icon.color">
            {{ item.icon.text }}
          </v-icon>
          <span v-html="item.text"></span>
        </a>
      </li>
    </router-link>
    <slot name="append-menu"> </slot>
  </ul>
</template>

<script>
// import { deepSearchWithPath } from '../../js/utilities';

export default {
  name: 'AppMenu',
  props: {
    items: Array,
    activeItem: String,
    view: {
      type: String, // 'vertical', 'horizontal'
      default: 'horizontal',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.app-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  //font-family: $heading-font-family;
  font-size: 16px;

  &__link {
    text-decoration: none;
    border: 0;
    color: $text-base;

    &:hover {
      color: var(--q-primary);
    }
  }

  &--horizontal {
    display: flex;

    .app-menu__item {
      display: flex;
      align-items: center;
      padding: 0 12px;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        height: 3px;
        background: var(--q-primary);
        transition: max-width 0.4s;
        max-width: 0;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(255, 255, 255, 0.24);
        color: #212121;
      }

      &.active {
        &::after {
          width: 100%;
          max-width: 200px;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &--vertical {
    .app-menu__item {
      position: relative;
      padding: 0;
      margin: 0 0 12px;

      &.active {
        .app-menu__link::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0.6em;
          width: 24px;
          height: 2px;
          background-color: var(--q-primary);
        }
        .app-menu__item-icon {
          color: var(--q-primary);
        }
      }

      &.active .app-menu__link,
      .app-menu__link:active,
      .app-menu__link:focus {
        color: var(--q-primary);
        font-weight: 500;
      }
      &.with-icon {
        .app-menu__link::before {
          display: none;
        }
      }
    }

    .app-menu__link {
      display: inline-block;
      padding-left: 36px;
      line-height: 1.4;
    }

    .app-menu__item-icon {
      position: absolute;
      left: 6px;
      top: 0;
    }
  }
}
</style>
