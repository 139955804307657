import bookmarkStorageService from '@/services/bookmarkStorageService';
import { Srs } from '../interfaces/Srs';

interface SrsStoreState {
  srsItems: Srs[];
  bookmarks: Partial<Srs>[];
}

const srsModule = {
  namespaced: true,
  state: (): SrsStoreState => ({
    srsItems: [],
    bookmarks: bookmarkStorageService.getBookmarks() || [],
  }),
  getters: {
    isSrsBookmarked: (state: SrsStoreState) => (id: number) => {
      const foundSrs = state.bookmarks.filter((item) => item.id === id);
      return !!foundSrs.length;
    },
  },
  mutations: {
    SET_SRS_ITEMS(state: SrsStoreState, items: Srs[]) {
      state.srsItems = items;
    },
    SET_BOOKMARKS(state: SrsStoreState, bookmarks: Partial<Srs>[]) {
      state.bookmarks = bookmarks;
      bookmarkStorageService.setBookmarks(bookmarks);
    },
    ADD_BOOKMARK(state: SrsStoreState, bookmark: Partial<Srs>) {
      state.bookmarks.push(bookmark);
      bookmarkStorageService.addBookmark(bookmark);
    },
    DELETE_BOOKMARK(state: SrsStoreState, id: number) {
      const indexToDelete = state.bookmarks.findIndex((item) => item.id === id);
      state.bookmarks.splice(indexToDelete, 1);
      bookmarkStorageService.setBookmarks(state.bookmarks);
    },
  },
};

export default srsModule;
