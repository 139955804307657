import { Srs } from "@/srs/interfaces/Srs";

const getBookmarks = (): Partial<Srs>[] => {
  const bookmarks: string = localStorage.getItem('bookmarks') || '[]';
  return JSON.parse(bookmarks);
};

const setBookmarks = (items: Partial<Srs>[]) => {
  localStorage.setItem('bookmarks', JSON.stringify(items));
};

const addBookmark = (item: Partial<Srs>) => {
  const newBookmarks = [
    ...getBookmarks(),
    item,
  ]
  setBookmarks(newBookmarks);
};

const deleteBookmark = (id: number) => {
  const newBookmarks = getBookmarks().filter(item => item.id !== id);
  setBookmarks(newBookmarks);
}

export default {
  getBookmarks, setBookmarks, addBookmark, deleteBookmark
}