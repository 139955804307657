import { BannerSettings } from '@/interfaces/BannerSettings';

export const BANNERS: BannerSettings = {
  en: [
    {
      alt: 'NextGIS Data',
      src: '/images/data_en.png',
      href: 'https://data.nextgis.com/en/?utm_source=nextgis&utm_medium=banner&utm_campaign=dataen_srs',
    },
    {
      alt: 'NextGIS WEB',
      src: '/images/ngw_en.png',
      href: 'https://nextgis.com/nextgis-com/?utm_source=nextgis&utm_medium=banner&utm_campaign=ngwru_srs',
    },
  ],
  ru: [
    {
      alt: 'NextGIS Data',
      src: '/images/data_ru.png',
      href: 'https://data.nextgis.com/ru/?utm_source=nextgis&utm_medium=banner&utm_campaign=dataru_srs',
    },
    {
      alt: 'NextGIS WEB',
      src: '/images/ngw_ru.png',
      href: 'https://nextgis.ru/nextgis-com/?utm_source=nextgis&utm_medium=banner&utm_campaign=ngwen_srs',
    },
  ],
};
