import { Settings } from '@/interfaces/Settings';

const localSettings = {};
// if (process.env.NODE_ENV === 'development') {
//   try {
//     localSettings = require('../settings.json');
//   } catch {
//     //
//   }
// }

const browserLanguage =navigator.languages ? navigator.languages[0] : navigator.language;
const defaultSettings: Settings = {
  title: 'Spatial references catalog',
  baseUrl:
    process.env.NODE_ENV === 'development' ? 'http://localhost:80' : '',
  defaultLanguage: localStorage.getItem('language') || browserLanguage || 'en',
  fallbackLanguage: 'en',
  languages: [
    {
      value: 'en',
      label: 'EN',
    },
    {
      value: 'ru',
      label: 'RU',
    },
  ],
};

export default { ...defaultSettings, ...localSettings };
