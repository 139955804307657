import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { createHead } from '@vueuse/head'
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import quasarUserOptions from './quasar-user-options';

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createHead())
  .mount('#app');
