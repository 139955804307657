import Notify from 'quasar/src/plugins/Notify.js';;
import './styles/quasar.scss';
import iconSet from 'quasar/icon-set/mdi-v4.js';
import '@quasar/extras/mdi-v4/mdi-v4.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#0070c5',
      accent: '#00b77e',
      secondary: '#e5eef7',
      info: '#2196f3',
      warning: '#ffc107',
      error: '#ff5252',
      success: '#4caf50',
    },
    screen: {
      bodyClasses: true,
    }
  },
  plugins: {
    Notify
  },
  iconSet,
};
